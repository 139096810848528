<template>
  <div class="left-section">
    <div class="pt-5 d-flex flex-column" style="width: 320px;">
      <volunteer-info-card :profile="profile" class="mb-5" />
      <list-info-card
        :icon="require('@/assets/svg/donations.svg')"
        :title="$t('donate')"
        :onClicked="onDonate"
        class="rounded-0"
      />
      <list-info-card
        :icon="require('@/assets/svg/world.svg')"
        :title="$t('my_agencies')"
        :text="`${sideSection.agency} Agencies`"
        :onClicked="goToAgencies"
        class="rounded-0"
      />
         <list-info-card
        :icon="require('@/assets/svg/member.svg')"
        :title="$t('join_as_member')"
        :text="`Join`"
        :onClicked="goToMembers"
        class="rounded-0"
      />
      <list-info-card
        :icon="require('@/assets/svg/volunteers.svg')"
        :title="$t('change_makers')"
        :text="sideSection.volunteer + ' volunteers'"
        :onClicked="goToVolunteers"
        :showSeperator="false"
        class="rounded-lg rounded-t-0"
      />
      <v-card class="my-5 rounded-lg">
        <v-card-title class="d-flex">
          <div
            class="justify-center flex-grow rounded-lg d-flex flex-column align-center circle-button hover-item"
            @click="goToCalendar"
          >
            <div class="inside-circle">
              <v-img
                :src="require('@/assets/svg/calendar.svg')"
                width="45"
                height="45"
                contain
              />
            </div>
            <span class="primary-color font-10"
              >{{ $t("needs") }} {{ $t("calendar") }}</span
            >
          </div>
          <div
            class="justify-center flex-grow rounded-lg d-flex flex-column align-center circle-button hover-item"
            @click="goToChat"
          >
            <div class="inside-circle">
              <v-img
                :src="require('@/assets/svg/chat.svg')"
                width="45"
                height="45"
                contain
              />
            </div>
            <span class="primary-color font-10"
              >{{ $t("volunteer") }} chat</span
            >
          </div>
          <div
            class="justify-center flex-grow rounded-lg d-flex flex-column align-center circle-button hover-item"
            @click="goToAwards"
          >
            <div class="inside-circle">
              <v-img
                :src="require('@/assets/svg/awards.svg')"
                width="45"
                height="45"
                contain
              />
            </div>
            <span class="primary-color font-10"
              >{{ $t("volunteer") }} {{ $t("awards") }}</span
            >
          </div>
        </v-card-title>
      </v-card>
      <list-info-card
        :icon="require('@/assets/svg/needs-blue.svg')"
        :title="$t('volunteer_opportunities')"
        :onClicked="goToNeeds"
        v-if="$route.name != 'agency-needs'"
        class="rounded-lg rounded-b-0"
      />
      <list-info-card
        :icon="require('@/assets/svg/contacts.svg')"
        :title="$t('community_chat_room')"
        :onClicked="goToChat"
        class="rounded-0"
      />
      <list-info-card
        :icon="require('@/assets/svg/weather.svg')"
        :title="$t('weather')"
        :onClicked="goToWeather"
        class="rounded-0"
      />
      <list-info-card
        :icon="require('@/assets/svg/map.svg')"
        :title="$t('map')"
        :onClicked="goToMap"
        class="rounded-0"
      />
      <list-info-card
        :icon="require('@/assets/svg/blog.svg')"
        title="Blog"
        :text="sideSection.blog + ' articles'"
        :onClicked="goToBlogs"
        class="mb-10 rounded-lg rounded-t-0"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ListInfoCard from "../../components/common/ListInfoCard.vue";
import VolunteerInfoCard from "./VolunteerInfoCard";
export default {
  components: { ListInfoCard, VolunteerInfoCard },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      sideSection: "sideSection"
    })
  },
  methods: {
    ...mapActions("auth", {
      getLeftSectionInfo: "getLeftSectionInfo"
    }),
    goToVolunteers() {
      if (this.$route.name != "volunteers") {
        this.$router.push({ name: "volunteers" });
      }
    },
    goToStore() {
      if (this.$route.name != "agency-shop") {
        this.$router.push({ name: "agency-shop" });
      }
    },
    goToAgencies() {
      if (this.$route.name != "volunteer-agencies") {
        this.$router.push({ name: "volunteer-agencies" });
      }
    },
    goToMembers() {
      if (this.$route.name != "join-as-member") {
        this.$router.push({ name: "join-as-member" });
      }
    },
    goToNeeds() {
      if (this.$route.name != "agency-need-feeds") {
        this.$router.push({ name: "agency-need-feeds" });
      }
    },
    goToChat() {
      this.$router.push({ name: "chat" });
    },
    goToBlogs() {
      if (this.$route.name != "blog-feeds") {
        this.$router.push({ name: "blog-feeds" });
      }
    },
    goToCalendar() {
      if (this.$route.name != "calendar") {
        this.$router.push({ name: "calendar" });
      }
    },
    goToAwards() {
      if (this.$route.name != "awards") {
        this.$router.push({ name: "awards" });
      }
    },
    goToMap() {
      if (this.$route.name != "need-map") {
        this.$router.push({ name: "need-map" });
      }
    },
    goToWeather() {
      if (this.$route.name != "weather") {
        this.$router.push({ name: "weather" });
      }
    },
    onDonate() {
      if (this.$route.name != "donation-select-agency") {
        this.$router.push({ name: "donation-select-agency" });
      }
    }
  },
  mounted() {
    this.loading = true;
    this.getLeftSectionInfo()
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        console.log(error.response.data.message);
      });
  }
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 56px;
  max-width: 430px;
  min-width: 430px;
  width: 430px;
  padding-left: 100px;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.left-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
