<template>
  <div class="weather-card">
    {{ updatedDate }}
    <div style="width: 100%; height: 100px;" class="d-flex align-center">
      <v-img :src="forecast_icon" contain width="100" height="100" />
    </div>
    <div class="d-flex flex-row justify-center">
      <div style="font-size: 14px;">
        {{ weather.temp.max }}&deg;{{ $parent.unit == "imperial" ? "F" : "C" }}
      </div>
      <div style="font-size: 13px; color: grey;" class="ml-3">
        {{ weather.temp.min }}&deg;{{ $parent.unit == "imperial" ? "F" : "C" }}
      </div>
    </div>
    <div
      class="d-flex align-center justify-center"
      style="font-size: 13px; font-weight: bold; margin-top: 5px;"
    >
      {{ getWeatherString }}
    </div>
  </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
  props: {
    weather: Object
  },
  methods: {
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    }
  },
  computed: {
    updatedDate() {
      if (!this.weather) return "";
      var date = new Date(this.weather.dt * 1000);
      return dateFormat(this.convertTZ(date), "ddd d");
    },
    forecast_icon() {
      let icon = "";
      try {
        icon = this.weather.weather[0]["icon"] || "01d";
      } catch {
        icon = "01d";
      }
      return require(`@/assets/weather/${icon}.png`);
      // return `http://openweathermap.org/img/wn/${icon}@2x.png`;
    },
    getWeatherString() {
      if (!this.weather) return "";
      if (this.weather.weather && this.weather.weather.length > 0) {
        let desc = this.weather.weather[0].description;
        return desc.charAt(0).toUpperCase() + desc.slice(1);
      }
      return "";
    }
  }
};
</script>
<style>
.weather-card {
  padding: 12px;
  background: #ffffffdd;
  border-radius: 10px;
}
</style>
