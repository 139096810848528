<template>
  <v-card class="w-100" v-if="weather" color="#ffffffdd">
    <v-card-title>
      <span style="font-size: 20px; font-weight: bold;">{{ address }}</span>
    </v-card-title>
    <v-card-title>
      {{ current_date }}
      <div class="d-flex flex-row align-center ml-5">
        <v-switch v-model="unit" />&deg;{{ unit ? "F" : "C" }}
      </div>
    </v-card-title>
    <v-card-subtitle
      class="d-flex align-center mb-0 pb-0"
      :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
    >
      <div>
        <v-img
          blank-color="#777"
          :src="forecast_icon"
          alt="forecast icon"
          contain
          height="120"
          :max-width="$vuetify.breakpoint.smAndDown ? 120 : 250"
        />
      </div>
      <div class="ml-3">
        <div>
          <v-icon class="mr-4" color="primary">
            mdi-thermometer
          </v-icon>
          <span class="content">
            {{
              weather.main.temp
                ? `${weather.main.temp}&deg;${this.unit ? "F" : "C"}`
                : ""
            }}
          </span>
        </div>
        <div style="font-weight: bold;">
          {{ current_description || "" }}
        </div>
      </div>
      <div
        :class="
          $vuetify.breakpoint.xs ? 'd-flex flex-column mt-3 mb-3' : 'ml-15'
        "
      >
        <span class="mr-3">Cloudiness: {{ weather.clouds.all }}%</span>
        <span class="mr-3"
          >Feels like: {{ weather.main.feels_like }}&deg;{{
            this.unit ? "F" : "C"
          }}</span
        >
        <span class="mr-3">Visibility: {{ getVisibility }}</span>
        <br />
        <div class="mr-3 d-flex">
          Wind:
          <div>
            <v-img
              :src="degreesToCompass(weather.wind.deg)"
              width="20"
              height="20"
            />
          </div>
          {{ weather.wind.speed }}{{ this.unit ? "mph" : "m/s" }}
        </div>
        <span class="mr-3">Humidity: {{ weather.main.humidity }}%</span>
        <span class="mr-3">Pressure: {{ weather.main.pressure }}hPa</span><br />
        <span class="mr-3">
          Max temp: {{ weather.main.temp_max }}&deg;{{ this.unit ? "F" : "C" }}
        </span>
        <span class="mr-3">
          Min temp: {{ weather.main.temp_min }}&deg;{{ this.unit ? "F" : "C" }}
        </span>
      </div>
    </v-card-subtitle>
    <v-card-text
      class="primary-color"
      :class="$vuetify.breakpoint.xs ? 'd-flex justify-center' : 'ml-15'"
    >
      Updated as of {{ updatedDate }}
    </v-card-text>
  </v-card>
</template>
<script>
import dateFormat from "dateformat";
export default {
  name: "Forecast",
  props: {
    weather: Object,
    address: String
  },
  data() {
    return {
      unit: true
    };
  },
  methods: {
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    },
    degreesToCompass(deg) {
      if (deg == undefined) return "";
      var val = Math.floor(deg / 22.5 + 0.5);
      var dir = [
        "N",
        "NNE",
        "NE",
        "ENE",
        "E",
        "ESE",
        "SE",
        "SSE",
        "S",
        "SSW",
        "SW",
        "WSW",
        "W",
        "WNW",
        "NW",
        "NNW"
      ];
      return require(`@/assets/wind/${dir[val % 16]}.svg`);
    }
  },
  watch: {
    "$parent.unit": function(newValue) {
      if ((newValue == "imperial") != this.unit) {
        this.unit = newValue == "imperial";
      }
    },
    unit(newValue) {
      if (newValue != (this.$parent.unit == "imperial")) {
        this.$parent.switchUnit();
      }
    }
  },
  computed: {
    current_date() {
      let dateStr = new Date();
      return dateFormat(this.convertTZ(dateStr), "dddd, mmmm d, yyyy");
    },
    current_temperature() {
      let temp = this.temperature ? Math.round(this.temperature.temp) : "";
      return temp;
    },
    current_condition() {
      let condition = this.condition ? this.condition.main : "";
      return condition;
    },
    current_description() {
      let desc = this.weather ? this.weather.weather[0]["description"] : "";
      return desc.charAt(0).toUpperCase() + desc.slice(1);
    },
    getVisibility() {
      if (!this.weather) return "";
      var weather = this.weather.visibility;
      if (weather >= 1000) {
        return this.weather.visibility / 1000 + " km";
      } else return this.weather.visibility + " m";
    },
    updatedDate() {
      if (!this.weather) return "";
      var date = new Date(this.weather.dt * 1000);
      return dateFormat(this.convertTZ(date), "h:MM TT");
    },
    forecast_icon() {
      let icon = "";
      try {
        icon = this.weather.weather[0]["icon"] || "01d";
      } catch {
        icon = "01d";
      }
      return require(`@/assets/weather/${icon}.png`);
    }
  }
};
</script>
