<template>
  <div>
    <v-text-field
      @keypress="setSearch"
      v-model="search"
      type="text"
      label="City, State"
      solo
      prepend-inner-icon="mdi-magnify"
      full-width
    />
  </div>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {
      search: ""
    };
  },
  methods: {
    setSearch(e) {
      if (e.key == "Enter" && this.search.length !== 0) {
        //alert("running");
        let params = {
          location: this.search
        };
        this.$emit("setWeather", this.search);
        this.search = "";
        return params;
      }
    }
  }
};
</script>

<style></style>
