<template>
  <v-card class="rounded-lg">
    <div class="justify-between pb-5 d-flex flex-column align-center">
      <v-img
        :src="
          profile.bgImage
            ? profile.bgImage
            : require('@/assets/background0.png')
        "
        width="100%"
        style="flex-grow: 1"
        :aspect-ratio="$vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455"
        class="rounded-lg rounded-b-0 position-absolute"
      />
      <v-tooltip bottom v-if="getCompletedInfo">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="red"
            v-text="'mdi-alert'"
            v-bind="attrs"
            v-on="on"
            style="position:absolute; right: 10px; top: 10px;"
            @click="goToSetting"
          />
        </template>
        <span>Uncompleted profile: Missing {{ getCompletedInfo }}</span>
      </v-tooltip>
      <volunteer-avatar
        :img="
          profile.midThumbnail
            ? profile.midThumbnail
            : profile.image
            ? profile.image
            : placeholderImage
        "
        class="mt-10 mb-1"
        :name="profile.name"
      />
      <span
        class="px-2 mt-5 text-center default-font-color url-button app-bold-font font-18"
        @click="goToProfile"
        v-if="profile.first_name"
      >
        {{ profile.first_name + " " + profile.last_name }}
      </span>
      <span
        class="px-2 mt-3 text-center primary-color app-medium-font font-12"
        v-if="profile.address"
      >
        <v-icon color="#5C5B62" v-text="'mdi-map-marker-outline'" size="18" />
        {{ profile.address }},
        {{ profile.address2 ? profile.address2 + ", " : "" }} {{ profile.city }}
      </span>
      <div class="justify-between px-8 mt-3 d-flex w-100 position-relative">
        <span
          class="px-2 mt-2 cursor-pointer primary-color font-12 app-bold-font"
          @click="goToWorkHours"
          ><v-icon
            v-text="'mdi-clock-outline'"
            size="17"
            class="mr-1 primary-color"
          />{{ Math.round(profile.volunteered_hours / 60) }} hours</span
        >
        <span class="px-2 mt-2 primary-color followers font-12 app-bold-font" @click="followers"
          ><v-icon
            v-text="'mdi-eye-outline'"
            size="18"
            class="mr-1 primary-color followers"
            @click="followers"
          />{{
            profile.followers && profile.followers.length
              ? profile.followers.length
              : 0
          }}
          Followers</span
        >
        <div
          class="position-absolute"
          style="background-color: #ADADB1; width: 1px; height: 20px; top: 5px; bottom: 0px; left: 50%;"
        />
      </div>
    </div>
  </v-card>
</template>
<script>
import VolunteerAvatar from "./VolunteerAvatar";
export default {
  components: { VolunteerAvatar },
  props: {
    profile: Object,
    onClicked: Function
  },
  data() {
    return {
      placeholderImage: require("@/assets/default.jpg")
    };
  },
  computed: {
    getCompletedInfo() {
      if (!this.profile._id) return "";
      var str = "";
      if (!this.profile.image) str += "Image";
      if (!this.profile.about_me) str ? (str += ", Image") : (str = "Image");
      if (!this.profile.phone_verified)
        str ? (str += ", Phone not verified") : (str = "Phone not verified");
      if (!this.profile.email_verified || !this.profile.email_verified)
        str ? (str += ", Email not verified") : (str = "Email not verified");
      if (this.profile.interests && this.profile.interests.length == 0) {
        str ? (str += ", Interests not set") : (str = "Interests not set");
      }
      if (this.profile.causes && this.profile.causes.length == 0) {
        str ? (str += ", Causes not set") : (str = "Causes not set");
      }
      if (
        this.profile.availabilities &&
        this.profile.availabilities.length == 0
      ) {
        str
          ? (str += ", Availabilities not set")
          : (str = "Availabilities not set");
      }
      if (!this.profile.phone) str ? (str += ", Phone") : (str = "Phone");
      if (!this.profile.address) str ? (str += ", Address") : (str = "Address");
      if (!this.profile.city) str ? (str += ", City") : (str = "City");
      if (!this.profile.state) str ? (str += ", State") : (str = "State");
      if (!this.profile.zip) str ? (str += ", Zip") : (str = "Zip");

      return str;
    }
  },
  methods: {
    goToProfile() {
      this.$router.push({
        name: "volunteer-profile-details",
        params: { id: this.profile._id }
      });
    },
    goToSetting() {
      if (!this.profile.email_verified || !this.profile.phone_verified) {
        this.$router.push({ name: "settings" });
      } else {
        this.$router.push({
          name: "volunteer-profile-about",
          params: { id: this.profile._id }
        });
      }
    },
    goToWorkHours() {
      this.$router.push({
        name: "volunteer-hours"
      });
    },
    followers(){
      this.$router.push({
        name: "volunteers",
      });
    }
  }
};
</script>
<style scoped>
.followers {
  cursor: pointer;
}
</style>