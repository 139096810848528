var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"rounded-lg"},[_c('div',{staticClass:"justify-between pb-5 d-flex flex-column align-center"},[_c('v-img',{staticClass:"rounded-lg rounded-b-0 position-absolute",staticStyle:{"flex-grow":"1"},attrs:{"src":_vm.profile.bgImage
          ? _vm.profile.bgImage
          : require('@/assets/background0.png'),"width":"100%","aspect-ratio":_vm.$vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455}}),(_vm.getCompletedInfo)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"position":"absolute","right":"10px","top":"10px"},attrs:{"color":"red"},domProps:{"textContent":_vm._s('mdi-alert')},on:{"click":_vm.goToSetting}},'v-icon',attrs,false),on))]}}],null,false,2494622637)},[_c('span',[_vm._v("Uncompleted profile: Missing "+_vm._s(_vm.getCompletedInfo))])]):_vm._e(),_c('volunteer-avatar',{staticClass:"mt-10 mb-1",attrs:{"img":_vm.profile.midThumbnail
          ? _vm.profile.midThumbnail
          : _vm.profile.image
          ? _vm.profile.image
          : _vm.placeholderImage,"name":_vm.profile.name}}),(_vm.profile.first_name)?_c('span',{staticClass:"px-2 mt-5 text-center default-font-color url-button app-bold-font font-18",on:{"click":_vm.goToProfile}},[_vm._v(" "+_vm._s(_vm.profile.first_name + " " + _vm.profile.last_name)+" ")]):_vm._e(),(_vm.profile.address)?_c('span',{staticClass:"px-2 mt-3 text-center primary-color app-medium-font font-12"},[_c('v-icon',{attrs:{"color":"#5C5B62","size":"18"},domProps:{"textContent":_vm._s('mdi-map-marker-outline')}}),_vm._v(" "+_vm._s(_vm.profile.address)+", "+_vm._s(_vm.profile.address2 ? _vm.profile.address2 + ", " : "")+" "+_vm._s(_vm.profile.city)+" ")],1):_vm._e(),_c('div',{staticClass:"justify-between px-8 mt-3 d-flex w-100 position-relative"},[_c('span',{staticClass:"px-2 mt-2 cursor-pointer primary-color font-12 app-bold-font",on:{"click":_vm.goToWorkHours}},[_c('v-icon',{staticClass:"mr-1 primary-color",attrs:{"size":"17"},domProps:{"textContent":_vm._s('mdi-clock-outline')}}),_vm._v(_vm._s(Math.round(_vm.profile.volunteered_hours / 60))+" hours")],1),_c('span',{staticClass:"px-2 mt-2 primary-color followers font-12 app-bold-font",on:{"click":_vm.followers}},[_c('v-icon',{staticClass:"mr-1 primary-color followers",attrs:{"size":"18"},domProps:{"textContent":_vm._s('mdi-eye-outline')},on:{"click":_vm.followers}}),_vm._v(_vm._s(_vm.profile.followers && _vm.profile.followers.length ? _vm.profile.followers.length : 0)+" Followers")],1),_c('div',{staticClass:"position-absolute",staticStyle:{"background-color":"#ADADB1","width":"1px","height":"20px","top":"5px","bottom":"0px","left":"50%"}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }